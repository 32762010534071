<template>
  <div>
    <b-row>
      <b-col
        lg="4"
        md="6"
        sm="12"
        xs="12"
      >
        <b-card>
          <b-form>
            <validation-observer
              ref="simpleRules"
              v-slot="{ invalid }"
            >
              <b-form-group
                label="Nombre de la Organización"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-input
                    v-model="organization.name"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Ciudad"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ciudad"
                  rules="required"
                >
                  <b-form-input
                    v-model="organization.city"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="País"
              >
                <b-form-select
                  v-model="selectedCountry"
                  :options="countryOptions"
                />
              </b-form-group>
              <b-form-group
                label="Sector"
              >
                <b-form-select
                  v-model="organization.sector"
                  :options="sectorOptions"
                />
              </b-form-group>
              <b-form-group
                label="Red"
              >
                <b-form-select
                  v-model="selectedNetwork"
                  :options="networkOptions"
                />
              </b-form-group>
              <b-button
                variant="success"
                :disabled="saving || invalid"
                @click="onSaveClick"
              >
                Guardar
                <b-spinner
                  v-if="saving"
                  small
                />
              </b-button>
            </validation-observer>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormSelect,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import organizations from '../../../services/organizations'
import countries from '../../../services/countries'
import networks from '../../../services/networks'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      organization: {
        name: '',
        logo: '',
        city: '',
        sector: 'Educación',
        country: {
          id: 'ec',
          name: 'Ecuador',
        },
        network: {
          id: '',
          name: '',
        },
        contact: {
          id: '',
          name: '',
        },
        advisor: {
          id: '',
          name: '',
        },
      },
      countries: [],
      networks: [],
      selectedCountry: 'ec',
      selectedNetwork: '',
      selectedContact: '',
      selectedAdvisor: '',
      sectorOptions: [
        'Educación',
        'Salud',
      ],
      saving: false,
      required,
    }
  },
  computed: {
    countryOptions() {
      return this.countries.map(c => ({ value: c['.key'], text: c.name }))
    },
    networkOptions() {
      const netOptns = this.networks.map(n => ({ value: n['.key'], text: n.name }))
      let result = [{ value: '', text: '(ninguna)' }]
      result = result.concat(netOptns)
      return result
    },
  },
  firebase: {
    countries: countries.bindAll(),
    networks: networks.bindAll(),
  },
  methods: {
    async onSaveClick() {
      this.saving = true
      this.organization.country = (this.selectedCountry !== '')
        ? this.countries.map(c => ({ id: c['.key'], name: c.name })).find(c => c.id === this.selectedCountry)
        : { id: '', name: '' }
      this.organization.network = (this.selectedNetwork !== '')
        ? this.networks.map(n => ({ id: n['.key'], name: n.name })).find(n => n.id === this.selectedNetwork)
        : { id: '', name: '' }

      try {
        await organizations.create(this.organization)
        this.showNotification('Listo', 'Se creó la organización exitosamente', 'CheckIcon', 'success')
        this.$router.push({ name: 'admin-organizations' })
      } catch (err) {
        this.showNotification('Error al crear la organización', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.saving = true
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
